import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { all, resolve } from 'rsvp';
import { trackEvent } from '../../../utils/matomo-events';

export default Component.extend({
  store: service(),

  tagName: '',
  modalOpen: false,
  product: false,
  school: false,
  groupOrCourseId: null,
  userSearchVal: null,
  showTrial: false,
  availableProduct: null,

  externalLicenses: alias('school.metadata.external_licenses'),

  trialsDisabled: alias('school.metadata.trials_disabled'),

  assignDisabled: computed('externalLicenses', 'trialsDisabled', function() {
    return this.externalLicenses && this.trialsDisabled
  }),

  licensesNeedsUpdate: computed(
    'availableProduct.{licensesHaveChanged,availableProductIsReloading}',
    function() {
      return (
        this.get('availableProduct.licensesHaveChanged') &&
        !this.get('availableProduct.availableProductIsReloading')
      );
    }
  ),

  agreement: computed('availableProduct', function() {
    const product = this.get('availableProduct.isbn');

    return this.get('store').query('agreement', { isbn: product });
  }),

  didReceiveAttrs() {
    this._super(...arguments);

    trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Öppnar', name: location.href });

    all([this.product, this.school]).then(async ([product, school]) => {
      if (product && school) {
        let availableProducts = await resolve(school.available_products);

        if (availableProducts) {
          await availableProducts.reload();

          const isbn = product.isbn ? product.isbn : product.id;

          const availableProduct = availableProducts.find((x) => x.isbn === isbn);

          this.set('availableProduct', availableProduct);

          await availableProduct.fullReload();
        }
      }
    });
  },

  actions: {
    close() {
      later(() => {
        if (!this.get('isDestroying') || !this.get('isDestroyed')) {
          this.set('modalOpen', false);
        }
      });
    }
  }
});
