import config from 'babel-app/config/environment';

import ApplicationAdapter from './application';

export default class Adapter extends ApplicationAdapter {
  host = config.endpoint;
  namespace = 'api/v1/public';

  shouldReloadAll() {
    return true;
  }
}
