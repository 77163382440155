import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class extends Route {
  @service session;
  @service store;
  @service router;

  queryParams = {
    isbn: { refreshModel: false },
    refresh: { refreshModel: true },
  };

  async model(params) {
    const user = this.session.user;

    if (!user) {
      return {};
    }


    const [schools, active_school, schoolApplications, readNotices] = await Promise.all([
      user.get('schools'),
      user.role !== 'private' ? user.get('school') : null,
      user.get('schoolApplications'),
      user.get('readNotices'),
      user.get('licenses'),
      user.role !== 'private' ? user.get('courses') : [],
    ]);

    const schoolApplication = schoolApplications.toArray().sort((a, b) => b.created - a.created)[0];

    if (params.refresh) {
      this.router.transitionTo('master.index', { queryParams: { refresh: null } });
    }

    return {
      schoolApplication,
      user,
      userName: user.firstname ?? user.username,
      readNotices,
      active_school,
      schools,
    };
  }
}
