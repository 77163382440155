import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { all } from 'rsvp';
import { trackEvent } from '../../../utils/matomo-events';

export default Component.extend({
  // SERVICES

  store: service(),

  // EXTERNAL PROPERTIES

  user: null,

  // INTERNAL PROPERTIES

  userCourses: null,

  isLoading: true,

  // LIFECYCLE

  didReceiveAttrs() {
    this._super(...arguments);

    this.get('getCourses').perform();
  },

  // TASKS

  getCourses: task(function*() {
    const user = this.get('user');

    if (!user) {
      return;
    }

    this.set('isLoading', true);

    const userCourses = yield this.get('user.courses');

    this.set('userCourses', userCourses.sortBy('name'));

    const promises = [];

    userCourses.forEach((course) => {
      promises.push(course.get('teachers'));
    });

    yield all(promises);

    this.set('isLoading', false);
  }).drop(),

  openCourseSettings() {
    trackEvent({ category: 'Användare', action: `${ this.user.role === 'teacher' ? 'Lärare' : 'Elev' } - Kurs - Öppna kurs` });
  }
});
