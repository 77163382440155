import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { writeCookie } from 'babel-app/utils/handle-cookies';

export default Component.extend({
  tagName: '',

  cookies: service(),

  snackbar: service(),

  notice: service(),

  intl: service(),

  model: null,

  didReceiveAttrs() {
    this._super(...arguments);

    const model = this.get('model');
    const savedShown = this.get('notice.shownSnacks') || [];
    const shown = [];

    savedShown.forEach((snackId) => {
      if (!model || model.mapBy('id').indexOf(snackId) === -1) {
        this.get('snackbar').dismissById(snackId, { animate: false });
      } else {
        shown.push(snackId);
      }
    });

    this.set('notice.shownSnacks', shown);

    if (model && model.length > 0) {
      model.forEach((notice) => {
        const buttons = [];

        if (notice.get('body.action.label')) {
          buttons.push({
            label: notice.get('body.action.label'),
            position: 'left',
            dismissOnClick: true,
            onClick: () => {
              if (notice.get('body.action.target')) {
                window.open(notice.get('body.action.target'));
              }

              writeCookie(this.cookies, `digi-closedNotice_${notice.id}`, true);
            },
          });
        }

        buttons.push(
          {
            label: this.get('intl').t('notice.noThanks'),
            position: 'left',
            appearance: 'link',
            dismissOnClick: true,
            onClick: () => {
              writeCookie(this.cookies, `digi-closedNotice_${notice.id}`, true);
            },
          },
          {
            label: this.get('intl').t('notice.later'),
            position: 'right',
            appearance: 'link',
            dismissOnClick: true,
            onClick: () => {}
          }
        );

        const snack = {
          id: notice.get('id'),
          title: notice.get('title'),
          message: notice.get('message'),
          displayIcon: false,
          displayDismissButton: false,
          actionButtons: buttons
        };

        if (shown.indexOf(notice.get('id')) !== -1) {
          return this.get('snackbar').update(notice.get('id'), snack);
        }

        shown.push(notice.get('id'));

        this.set('notice.shownSnacks', shown);

        this.get('snackbar').enqueue(snack);
      });
    }
  }
});
